// extracted by mini-css-extract-plugin
export var acceleratedLink = "CourseSessionCard-module--accelerated-link--3zIbE";
export var banner = "CourseSessionCard-module--banner--BgnwK";
export var bannerAlmostFull = "CourseSessionCard-module--banner-almost-full--4TZtS";
export var bannerSoldOut = "CourseSessionCard-module--banner-sold-out--Iu4ML";
export var classAddress = "CourseSessionCard-module--class-address--Fc5yK";
export var classDates = "CourseSessionCard-module--class-dates--36X8-";
export var classDays = "CourseSessionCard-module--class-days--9D1HV";
export var classTimes = "CourseSessionCard-module--class-times--R-pvY";
export var fullWidth = "CourseSessionCard-module--full-width--3TtEa";
export var mobileDetailsPreview = "CourseSessionCard-module--mobile-details-preview--sgO5i";
export var programInfo = "CourseSessionCard-module--program-info--80mqM";
export var purchaseButton = "CourseSessionCard-module--purchase-button--qz8e1";
export var registerButton = "CourseSessionCard-module--register-button--MH4dW";
export var sessionDays = "CourseSessionCard-module--session-days--hhNN1";
export var sessionDetails = "CourseSessionCard-module--session-details--CoSce";
export var sessionInstructor = "CourseSessionCard-module--session-instructor--YoP3d";
export var sessionInstructorDetails = "CourseSessionCard-module--session-instructor-details--ha-jC";
export var sessionInstructorHeadshot = "CourseSessionCard-module--session-instructor-headshot--qyZVp";
export var singleSession = "CourseSessionCard-module--single-session--WBZCe";
export var timeCommitment = "CourseSessionCard-module--time-commitment--xgyiE";