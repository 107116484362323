// extracted by mini-css-extract-plugin
export var agreements = "forms-module--agreements--9tbwV";
export var contactInfo = "forms-module--contact-info--ixFsR";
export var customCheckbox = "forms-module--custom-checkbox--UUFBf";
export var error = "forms-module--error--gFhP4";
export var errorMessage = "forms-module--error-message--ZNkEq";
export var formEnd = "forms-module--form-end--L3buj";
export var longTextQuestion = "forms-module--long-text-question--hvc0V";
export var longTextResponse = "forms-module--long-text-response--WGXJ0";
export var question = "forms-module--question--5HyWg";
export var questionExamples = "forms-module--question-examples--ZsF5i";
export var required = "forms-module--required--Nxrvj";
export var showError = "forms-module--show-error--kpCjg";
export var submitButton = "forms-module--submit-button--vHOYh";