// extracted by mini-css-extract-plugin
export var acceleratedDetails = "course-details-module--accelerated-details--kkVB1";
export var banner = "course-details-module--banner--AKJqs";
export var careerPathway = "course-details-module--career-pathway--iBgHk";
export var cost = "course-details-module--cost--5v1fi";
export var courseDescription = "course-details-module--course-description--PPUUv";
export var courseDetailsSurtitle = "course-details-module--course-details-surtitle--Kof9y";
export var courseFaq = "course-details-module--course-faq--ysK1M";
export var coursePrice = "course-details-module--course-price--+jLw0";
export var description = "course-details-module--description--W9edO";
export var faqBlocks = "course-details-module--faq-blocks--ktvxV";
export var faqRedirect = "course-details-module--faq-redirect--GUzu0";
export var gridWrapper = "course-details-module--grid-wrapper--LokGU";
export var headline = "course-details-module--headline--MDbm0";
export var introDescription = "course-details-module--intro-description--n2ToG";
export var introDetails = "course-details-module--intro-details--uEevu";
export var linkContainer = "course-details-module--link-container--fb0qB";
export var linkSubhead = "course-details-module--link-subhead--7wvlH";
export var partTimeDetails = "course-details-module--part-time-details--nyyRy";
export var priceContainer = "course-details-module--price-container--WQyqC";
export var priceDescription = "course-details-module--price-description--ORl6r";
export var pricingSubhead = "course-details-module--pricing-subhead--EzCFK";
export var projectCarousel = "course-details-module--project-carousel--8uUwR";
export var projects = "course-details-module--projects--GOZTZ";
export var reviewCarousel = "course-details-module--review-carousel--3Iz0I";
export var reviewSection = "course-details-module--review-section--DXRFg";
export var reviews = "course-details-module--reviews--uupjb";
export var sectionHeading = "course-details-module--section-heading--QxUVD";
export var sessions = "course-details-module--sessions--AEL60";
export var sessionsNone = "course-details-module--sessions-none--Nj8gy";
export var studentExperience = "course-details-module--student-experience---q-Xw";
export var studentExperienceContainer = "course-details-module--student-experience-container--qH8lH";
export var studentExperienceContent = "course-details-module--student-experience-content--x+meT";
export var studentExperienceImage = "course-details-module--student-experience-image--ygQiz";
export var studentExperiencePlain = "course-details-module--student-experience-plain--YBGFS";
export var syllabus = "course-details-module--syllabus--JxeyK";
export var syllabusPackageText = "course-details-module--syllabus-package-text--q2Kdx";
export var syllabusPhoto = "course-details-module--syllabus-photo--YVSpR";
export var syllabusTopics = "course-details-module--syllabus-topics--KQkaR";